import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`You can install the JavaScript SDK using NPM or Yarn with the commands listed below:`}</p>
    <h4 {...{
      "id": "using-npm"
    }}>{`Using NPM`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install --save @paymentsds/mpesa
`}</code></pre>
    <h4 {...{
      "id": "using-yarn"
    }}>{`Using Yarn`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn add @paymentsds/mpesa
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      